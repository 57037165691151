const getters = {
  sidebar: (state) => state.app.sidebar,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  userInfo: (state) => state.user.info,
  userSettings: (state) => state.user.info.config,
  roles: (state) => state.user.roles,
  modules: (state) => state.user.info.modules,
  permission_routes: (state) => state.permission.routes,
  errorLogs: (state) => state.errorLog.logs,
  guides_done: (state) => state.guide.guides_done,
  loading: (state) => state.app.loading,
  lists: (state) => state.app.lists
}
export default getters
