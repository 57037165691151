/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Roles for Permissions
import roles from './roles'

const labReceivingRouter = {
  path: '/lab-receiving',
  component: Layout,
  redirect: 'noRedirect',
  name: 'LabReceivingModule',
  alwaysShow: true,
  meta: {
    module: 'LabReceiving',
    title: 'Lab Receiving',
    icon: 'warehouses',
    roles: [...roles.LabReceiving_SectionRoles_Section, ...roles.LabReceiving_Containers_Roles]
  },
  children: [
    {
      name: 'LabReceiving',
      path: 'receiving',
      component: () => import('@/views/lab-receiving/receiving'),
      meta: { title: 'Receiving', icon: 'receiving' }
    },
    {
      name: 'LabReceivingContainers',
      path: 'receiving_containers',
      component: () => import('@/views/lab-receiving/receiving-containers'),
      meta: { title: 'Receiving Containers', icon: 'containers', roles: roles.LabReceiving_Containers_Roles }
    },
    {
      name: 'LabReceivingContainerForm',
      path: 'receiving_container/:id?',
      component: () => import('@/views/lab-receiving/receiving-container-form'),
      meta: { title: 'Receiving Container Form', icon: 'containers', roles: roles.LabReceiving_Containers_Roles },
      hidden: true
    },
    {
      name: 'QrCodeGeneration',
      path: 'qr_code_generation',
      component: () => import('@/views/lab-receiving/qr-code-form'),
      meta: { title: 'Qr Code Generation', icon: 'qr-code' }
    }
  ]
}

export default labReceivingRouter
