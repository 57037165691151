/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Layout without Header
import { AppMain } from '@/layout/components'
// Roles for Permissions
import roles from './roles'

const rdRouter = {
  path: '/rd',
  component: Layout,
  redirect: 'noRedirect',
  name: 'rdModule',
  alwaysShow: true,
  meta: {
    module: 'RD',
    title: 'R&D',
    icon: 'rd',
    roles: [...roles.RD_PageRoles_Projects, ...roles.RD_PageRoles_Sample, ...roles.RD_PageRoles_RD]
  },
  children: [
    {
      path: '/rd/main',
      component: AppMain,
      name: 'rd-main',
      alwaysShow: true,
      meta: {
        title: 'MAIN',
        breadcrumb: false,
        roles: [...roles.RD_PageRoles_Projects, ...roles.RD_PageRoles_Sample, ...roles.RD_PageRoles_RD]
      },
      children: [
        {
          name: 'RdProjects',
          path: '/rd/projects',
          component: () => import('@/views/rd/projects/index'),
          meta: { title: 'Projects', icon: 'project', roles: roles.RD_PageRoles_Projects, noCache: true }
        },
        {
          name: 'RdProjectForm',
          path: '/rd/project/:id?',
          component: () => import('@/views/rd/projects/form'),
          meta: { title: 'Project', icon: 'project', roles: roles.RD_PageRoles_Projects, noCache: true },
          hidden: true
        },
        {
          name: 'RdSamples',
          path: '/rd/samples',
          component: () => import('@/views/rd/samples/index'),
          meta: { title: 'Samples', icon: 'samples', roles: roles.RD_PageRoles_Sample, noCache: true }
        },
        {
          name: 'RDSampleDevelop',
          path: '/rd/sample/develop/:id',
          component: () => import('@/views/rd/samples/develop'),
          meta: { title: 'Sample - Develop', roles: roles.RD_PageRoles_Sample, noCache: true },
          hidden: true
        },
        {
          name: 'RDSampleSpecs',
          path: '/rd/sample/specifications/:id',
          component: () => import('@/views/rd/samples/specifications'),
          meta: { title: 'Sample - Specs Sheet', roles: roles.RD_PageRoles_Sample, noCache: true },
          hidden: true
        }
      ]
    }
  ]
}

export default rdRouter
