const EMPTY_STATE = 'emptyState'
const ADD_HISTORY_STATE = 'addHistory'

export default {
  install(Vue) {
    if (!Vue._installedPlugins.find((plugin) => plugin.Store)) {
      throw new Error('VuexUndoRedo plugin must be installed after the Vuex plugin.')
    }
    Vue.mixin({
      data() {
        return {
          done: [],
          undone: [],
          newMutation: true
        }
      },
      computed: {
        canRedo() {
          return this.undone.length > 0
        },
        canUndo() {
          return this.done.length > 1
        }
      },
      created() {
        if (this.$store) {
          this.$store.subscribe((mutation) => {
            if (mutation.type !== EMPTY_STATE && mutation.type === ADD_HISTORY_STATE) {
              this.done.push(mutation)
            }
            if (this.newMutation && mutation.type === ADD_HISTORY_STATE) {
              this.undone = []
            }
          })
        }
      },
      methods: {
        async redo() {
          const commit = this.undone.pop()
          this.newMutation = false
          this.$store.commit(`${commit.type}`, commit.payload)
        },
        async undo() {
          const commit = this.done.pop()
          this.undone.push(commit)
          this.newMutation = false
          this.$store.commit(EMPTY_STATE)
          this.done.forEach((mutation) => {
            this.$store.commit(`${mutation.type}`, mutation.payload)
            this.done.pop()
          })
          this.newMutation = true
        }
      }
    })
  }
}
