/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Layout without Header
import { AppMain } from '@/layout/components'
// Roles for Permissions
import roles from './roles'

const forecastRouter = {
  path: '/forecast',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ForecastModule',
  alwaysShow: true,
  meta: {
    module: 'Forecast',
    title: 'Forecast',
    icon: 'forecast',
    roles: roles.Forecast_SectionRoles_Section
  },
  children: [
    {
      path: '/forecast-main',
      component: AppMain,
      name: 'forecast-main',
      alwaysShow: true,
      meta: {
        title: 'MAIN',
        roles: roles.Forecast_SectionRoles_Section,
        breadcrumb: false
      },
      children: [
        {
          name: 'ForecastPeriods',
          path: 'forecast-periods',
          component: () => import('@/views/forecast/periods/index'),
          meta: { title: 'Forecast Periods', icon: 'calendar', noCache: true }
        },
        {
          name: 'ForecastPeriodForm',
          path: '/forecast-periods/:id?',
          component: () => import('@/views/forecast/periods/period-form'),
          meta: { title: 'Forecast Period - Form', icon: 'calendar', noCache: true },
          hidden: true
        },
        // {
        //   name: 'ForecastAllocationParameters',
        //   path: 'forecast-allocation-parameters',
        //   component: () => import('@/views/forecast/allocations/allocation-parameters'),
        //   meta: { title: 'Allocation Parameters', icon: 'parameters', noCache: true }
        // },
        {
          name: 'ForecastAllocations',
          path: 'forecast-allocations/:period_id?',
          component: () => import('@/views/forecast/allocations/index'),
          meta: { title: 'Forecast Allocations', icon: 'daily-orders', noCache: true },
          hidden: true
        },
        {
          name: 'ForecastAllocationPlan',
          path: 'forecast-allocation-plan',
          component: () => import('@/views/forecast/allocations/allocation-plan'),
          meta: { title: 'Allocation Plan', icon: 'daily-orders', noCache: true },
          hidden: true
        },
        {
          name: 'ForecastProductionPlans',
          path: 'forecast-production-plans/:period_id?',
          component: () => import('@/views/forecast/production-plans/index'),
          meta: { title: 'Forecast Production Plans', icon: 'schedule', noCache: true },
          hidden: true
        },
        {
          name: 'ForecastProductionPlanForm',
          path: 'forecast-production-plan',
          component: () => import('@/views/forecast/production-plans/production-plan-form'),
          meta: { title: 'Forecast Production Plan - Form', icon: 'schedule', noCache: true },
          hidden: true
        },
        {
          name: 'ForecastProductionQtyRanges',
          path: 'forecast-production-qty-ranges',
          component: () => import('@/views/forecast/production-plans/production-qty-ranges'),
          meta: { title: 'Production Qty Ranges', icon: 'parameters', noCache: true }
        }
      ]
    },
    {
      path: '/forecast-reports',
      component: AppMain,
      name: 'forecast-reports',
      alwaysShow: true,
      meta: {
        title: 'REPORTS',
        roles: roles.Forecast_SectionRoles_Section,
        breadcrumb: false
      },
      children: [
        {
          name: 'ForecastSalesReport',
          path: 'sales-report',
          component: () => import('@/views/forecast/reports/sales-reports'),
          meta: { title: 'Forecast Sales Report', icon: 'list', noCache: true }
        },
        {
          name: 'ForecastStockReport',
          path: 'stock-report',
          component: () => import('@/views/forecast/reports/stock-reports'),
          meta: { title: 'Forecast Stock Report', icon: 'stock-report', noCache: true }
        }
      ]
    }
  ]
}

export default forecastRouter
