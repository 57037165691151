/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Roles for Permissions
import roles from './roles'

const trainingRouter = {
  path: '/training',
  component: Layout,
  redirect: 'noRedirect',
  name: 'TrainingModule',
  alwaysShow: true,
  meta: {
    module: 'Training',
    title: 'Training',
    icon: 'certificate-alt-2',
    roles: [
      ...roles.Training_PageRoles_Training,
      ...roles.Training_PageRoles_Change,
      ...roles.Training_PageRoles_Admin,
      ...roles.Training_PageRoles_Trainer
    ]
  },
  children: [
    {
      name: 'TrainingDocuments',
      path: 'training-documents',
      component: () => import('@/views/training/training-documents'),
      meta: { title: 'Trainings Documents', icon: 'documents', roles: roles.Training_PageRoles_Training }
    },
    {
      name: 'TrainingProjects',
      path: 'training-projects',
      component: () => import('@/views/training/training-projects'),
      meta: { title: 'Training Projects', icon: 'schedule', roles: roles.Training_PageRoles_Trainer }
    },
    {
      name: 'Trainings',
      path: 'trainings',
      component: () => import('@/views/training/trainings'),
      meta: { title: 'Trainings', icon: 'training-classes', roles: roles.Training_PageRoles_Trainer }
    },
    {
      name: 'TrainingLogs',
      path: 'training-logs',
      component: () => import('@/views/training/training-logs'),
      meta: { title: 'Training Logs', icon: 'training-logs', roles: roles.Training_PageRoles_Trainer }
    },
    {
      name: 'TrainingCategories',
      path: 'categories',
      component: () => import('@/views/training/categories'),
      meta: { title: 'Categories', icon: 'categories', roles: roles.Training_PageRoles_Change }
    },
    {
      name: 'TrainingSettings',
      path: 'training-settings',
      component: () => import('@/views/training/settings'),
      meta: { title: 'Settings', icon: 'settings-alt', roles: roles.Training_PageRoles_Admin }
    }
  ]
}

export default trainingRouter
