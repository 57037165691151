<template>
  <span>
    <el-input
      v-if="fieldType === 'text'"
      ref="erp-input"
      v-model="currentValue"
      :disabled="disabled"
      @change="handleChange" />
    <el-input-number
      v-if="fieldType === 'number'"
      ref="erp-input"
      v-model="currentValue"
      :disabled="disabled"
      :min="min"
      :max="max"
      :precision="precision"
      :controls="false"
      @change="handleChange"
      @focus="$event.target.select()"
      @clear="handleChange" />
    <el-input-number
      v-if="fieldType === 'currency'"
      ref="erp-input"
      v-model="currentValue"
      :disabled="disabled"
      :precision="2"
      :min="0"
      :controls="false"
      @focus="$event.target.select()"
      @change="handleChange"
      @clear="handleChange" />
    <el-date-picker
      v-if="fieldType === 'date' || fieldType === 'datetime'"
      ref="erp-input"
      v-model="currentValue"
      :disabled="disabled"
      :type="fieldType"
      :value-format="fieldType === 'datetime' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
      @change="handleChange"
      @clear="handleChange" />
  </span>
</template>
<script>
import { deepCopy } from '@/utils'
export default {
  name: 'ErpInput',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    value: {
      type: [String, Number, Object, Array],
      required: false,
      default() {
        return null
      }
    },
    fieldType: {
      type: String,
      required: false,
      default() {
        return 'text'
      }
    },
    min: {
      type: Number,
      required: false,
      default() {
        return -Infinity
      }
    },
    max: {
      type: Number,
      required: false,
      default() {
        return Infinity
      }
    },
    precision: {
      type: Number,
      required: false,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      currentValue: this.value,
      localPickerOptions: {
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              if (typeof picker.leftMonth !== 'undefined') {
                picker.$emit('pick', [new Date(), new Date()])
              } else {
                picker.$emit('pick', new Date())
              }
            }
          },
          {
            text: 'This Week',
            moment: this.$moment,
            onClick(picker) {
              if (typeof picker.leftMonth !== 'undefined') {
                picker.$emit('pick', [this.moment().startOf('week').toDate(), this.moment().endOf('week').toDate()])
              } else {
                picker.$emit('pick', this.moment().startOf('week').toDate())
              }
            }
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              if (typeof picker.leftMonth !== 'undefined') {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', [start, end])
              } else {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            }
          },
          {
            text: 'A week ago',
            onClick(picker) {
              if (typeof picker.leftMonth !== 'undefined') {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              } else {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              }
            }
          },
          {
            text: 'A week later',
            onClick(picker) {
              if (typeof picker.leftMonth !== 'undefined') {
                const end = new Date()
                const start = new Date()
                end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              } else {
                const date = new Date()
                date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              }
            }
          },
          {
            text: 'This month',
            moment: this.$moment,
            onClick(picker) {
              if (typeof picker.leftMonth !== 'undefined') {
                const end = this.moment().endOf('month').toDate()
                const start = this.moment().startOf('month').toDate()
                picker.$emit('pick', [start, end])
              } else {
                picker.$emit('pick', this.moment().startOf('month').toDate())
              }
            }
          },
          {
            text: 'Last month',
            moment: this.$moment,
            onClick(picker) {
              if (typeof picker.leftMonth !== 'undefined') {
                const end = this.moment().subtract(1, 'months').endOf('month').toDate()
                const start = this.moment().subtract(1, 'months').startOf('month').toDate()
                picker.$emit('pick', [start, end])
              } else {
                picker.$emit('pick', this.moment().subtract(1, 'months').startOf('month').toDate())
              }
            }
          },
          {
            text: 'Next month',
            moment: this.$moment,
            onClick(picker) {
              if (typeof picker.leftMonth !== 'undefined') {
                const end = this.moment().add(1, 'months').endOf('month').toDate()
                const start = this.moment().add(1, 'months').startOf('month').toDate()
                picker.$emit('pick', [start, end])
              } else {
                picker.$emit('pick', this.moment().add(1, 'months').startOf('month').toDate())
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = deepCopy(newValue)
    }
  },
  methods: {
    focus() {
      // get the rendered input and call .focus
      this.$refs['erp-input'].focus()
    },
    handleChange(value) {
      this.$emit('input', value)
      this.$emit('el.form.change', [value])
      this.$emit('change', value)
    }
  }
}
</script>
