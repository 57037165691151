import request from '@/utils/request'

export function login(data) {
  data.grant_type = 'password'
  data.client_id = process.env.VUE_APP_CLIENT_ID
  data.client_secret = process.env.VUE_APP_CLIENT_SECRET

  return request({
    url: '/login',
    method: 'post',
    data
  })
}
export function punchIn(data) {
  return request({
    url: '/general/punch_in',
    method: 'post',
    data
  })
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/me',
    method: 'get',
    params: { token }
  })
}

export function find(query) {
  return request({
    url: '/general/users',
    method: 'get',
    params: query
  })
}

export function getTimezones() {
  return request({
    url: '/general/timezones',
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/general/users',
    method: 'post',
    data
  })
}

export function update(id, data) {
  return request({
    url: '/general/users/' + id,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: '/general/users/' + id,
    method: 'delete'
  })
}

export function findTeamLeaders(query) {
  query.role = 'team_leader'
  return request({
    url: '/general/users',
    method: 'get',
    params: query
  })
}

export function findSponsors(query) {
  query.role = 'sponsor'
  return request({
    url: '/general/users',
    method: 'get',
    params: query
  })
}

export function findBuyers(query) {
  query.role = 'buyer'
  return request({
    url: '/general/users',
    method: 'get',
    params: query
  })
}
export function findFlavorists(query) {
  query.role = 'rd_flavorist'
  query.list = '1'
  return request({
    url: '/general/users',
    method: 'get',
    params: query
  })
}

export function findAdmin(query) {
  query.role = 'admin'
  return request({
    url: '/general/users',
    method: 'get',
    params: query
  })
}

export function findDirector(query) {
  query.role = 'director'
  return request({
    url: '/general/users',
    method: 'get',
    params: query
  })
}

export function updateProfile(data) {
  return request({
    url: '/profile',
    method: 'post',
    data
  })
}

export function findBatchers(query) {
  query.role = 'batcher'
  return request({
    url: '/general/users',
    method: 'get',
    params: query
  })
}

export function getUserRoles() {
  return request({
    url: '/general/find_user_roles',
    method: 'get'
  })
}
