/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Layout without Header
import { AppMain } from '@/layout/components'
// Roles for Permissions
import roles from './roles'

const inventoryRouter = {
  path: '/inventory',
  component: Layout,
  redirect: 'noRedirect',
  name: 'InventoryModule',
  alwaysShow: true,
  meta: {
    module: 'Inventory',
    title: 'Inventory',
    icon: 'inventory',
    roles: [...roles.Inventory_SectionRoles_Section, ...roles.Inventory_Changes_Section]
  },
  children: [
    {
      path: '/inventory-main',
      component: AppMain,
      name: 'inventory-main',
      alwaysShow: true,
      meta: {
        title: 'MAIN',
        roles: [...roles.Inventory_SectionRoles_Section, ...roles.Inventory_Changes_Section],
        breadcrumb: false
      },
      children: [
        {
          name: 'Products',
          path: 'products',
          component: () => import('@/views/inventory/product/products'),
          meta: { title: 'Products', roles: roles.Inventory_SectionRoles_Section, icon: 'barcode', noCache: true }
        },
        {
          name: 'ProductsChanges',
          path: 'products_changes',
          component: () => import('@/views/inventory/change/products-changes'),
          meta: {
            title: 'Product Changes',
            roles: [...roles.Inventory_SectionRoles_Section, ...roles.Inventory_Changes_Section],
            icon: 'approval',
            noCache: true
          }
        },
        {
          name: 'ProductsChangeLogs',
          path: 'products_change_logs',
          component: () => import('@/views/inventory/change/products-change-logs'),
          meta: {
            title: 'Product Changes Logs',
            roles: [...roles.Inventory_SectionRoles_Section, ...roles.Inventory_Changes_Section],
            icon: 'list',
            noCache: true
          }
        },
        {
          name: 'ChangeSummary',
          path: 'change_summary',
          component: () => import('@/views/inventory/change/summary'),
          meta: {
            title: 'Changes Summary',
            roles: [...roles.Inventory_SectionRoles_Section, ...roles.Inventory_Changes_Section],
            icon: 'approval',
            noCache: true
          }
        },
        {
          name: 'SummaryDetails',
          path: '/change_summary/:id',
          component: () => import('@/views/inventory/change/summary-details'),
          meta: {
            title: 'Summary Details',
            roles: [...roles.Inventory_SectionRoles_Section, ...roles.Inventory_Changes_Section],
            icon: 'approval',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'ProductForm',
          path: '/products/:id?',
          component: () => import('@/views/inventory/product/product-form'),
          meta: {
            title: 'Product - Form',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'products',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'ProductChangeForm',
          path: '/product_changes/:id',
          component: () => import('@/views/inventory/change/product-change-form'),
          meta: {
            title: 'Product Changes - Form',
            roles: [...roles.Inventory_SectionRoles_Section, ...roles.Inventory_Changes_Section],
            icon: 'approval',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'ProductFetch',
          path: '/product/fetch',
          component: () => import('@/views/inventory/product/product-fetch'),
          meta: {
            title: 'Check Product',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'products',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'ProductBins',
          path: 'product-bins',
          component: () => import('@/views/inventory/product-bins'),
          meta: {
            title: 'Product Bins',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'files-stack',
            noCache: true
          }
        },
        {
          name: 'ProductFamily',
          path: 'products_family',
          component: () => import('@/views/inventory/product-family/index'),
          meta: {
            title: 'Products Family',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'merged-order',
            noCache: true
          }
        },
        {
          name: 'ProductFamilyForm',
          path: '/product-families/:id?',
          component: () => import('@/views/inventory/product-family/form'),
          meta: {
            title: 'Product Family - Form',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'products',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'Receivings',
          path: 'receivings',
          component: () => import('@/views/inventory/receiving/index'),
          meta: {
            title: 'Receivings',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'production-orders',
            noCache: true
          }
        },
        {
          name: 'ReceivingForm',
          path: '/receiving/:id?',
          component: () => import('@/views/inventory/receiving/receiving-form'),
          meta: {
            title: 'Receiving - Form',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'rickshaw',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'Allocation',
          path: 'allocation',
          component: () => import('@/views/inventory/allocation/packing-list'),
          meta: {
            title: 'Allocation',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'allocation',
            noCache: true
          }
        },
        {
          name: 'PackingListForm',
          path: '/allocation/:id?',
          component: () => import('@/views/inventory/allocation/packing-list-form'),
          meta: {
            title: 'Packing List - Form',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'allocation',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'StockOnHand',
          path: 'stock-on-hand',
          component: () => import('@/views/inventory/stock-count/stock-on-hand'),
          meta: { title: 'Stock On Hand', roles: roles.Inventory_SectionRoles_Section, icon: 'hand', noCache: true }
        },
        {
          name: 'StockCount',
          path: 'stock-count',
          component: () => import('@/views/inventory/stock-count/stock-count'),
          meta: {
            title: 'Stock Count',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'ui-calculator',
            noCache: true
          }
        },
        {
          name: 'StockCountForm',
          path: '/stock-count/:id',
          component: () => import('@/views/inventory/stock-count/stock-count-form'),
          meta: {
            title: 'Stock Count - Edit',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'hand',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'StockCountFormCreate',
          path: '/stock-count/:id?',
          component: () => import('@/views/inventory/stock-count/stock-count-form'),
          meta: {
            title: 'Stock Count - Create',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'hand',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'StockAdjustments',
          path: 'stock-adjustments',
          component: () => import('@/views/inventory/stock-adjustment/stock-adjustment'),
          meta: {
            title: 'Stock Adjustments',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'recipe_proposals',
            noCache: true
          }
        },
        {
          name: 'StockAdjustmentForm',
          path: 'stock-adjustment/:id?',
          component: () => import('@/views/inventory/stock-adjustment/stock-adjustment-form'),
          meta: { title: 'Stock Adjustment', roles: roles.Inventory_SectionRoles_Section, noCache: true },
          hidden: true
        },
        {
          name: 'Transfers',
          path: 'transfers',
          component: () => import('@/views/inventory/transfer/index'),
          meta: { title: 'Transfers', roles: roles.Inventory_SectionRoles_Section, icon: 'truck-loaded', noCache: true }
        },
        {
          name: 'TransferForm',
          path: 'transfer/:id?',
          component: () => import('@/views/inventory/transfer/transfer-form'),
          meta: {
            title: 'Transfer - Form',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'truck-loaded',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'Brands',
          path: 'brands',
          component: () => import('@/views/inventory/brands'),
          meta: { title: 'Brands', roles: roles.Inventory_SectionRoles_Section, icon: 'brands', noCache: true }
        },
        {
          name: 'Flavors',
          path: 'flavors',
          component: () => import('@/views/inventory/flavors'),
          meta: { title: 'Flavors', roles: roles.Inventory_SectionRoles_Section, icon: 'flavors', noCache: true }
        },
        {
          name: 'Categories',
          path: 'categories',
          component: () => import('@/views/inventory/categories'),
          meta: { title: 'Categories', roles: roles.Inventory_SectionRoles_Section, icon: 'categories', noCache: true }
        },
        {
          name: 'StockLocator',
          path: 'stock_locator',
          component: () => import('@/views/inventory/stock-locator'),
          meta: {
            title: 'Stock Locator',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'warehouses',
            noCache: true
          }
        },
        {
          name: 'UnitOfMeasure',
          path: 'measures',
          component: () => import('@/views/inventory/measure'),
          meta: {
            title: 'Unit of Measure',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'location-arrow',
            noCache: true
          }
        },
        {
          name: 'PriceTier',
          path: 'price-tiers',
          component: () => import('@/views/inventory/price-tier/price-tier'),
          meta: { title: 'Price Tier', roles: roles.Inventory_SectionRoles_Section, icon: 'price-tier', noCache: true }
        },
        {
          name: 'PriceTierForm',
          path: 'price-tier/:id?',
          component: () => import('@/views/inventory/price-tier/PriceTierForm'),
          meta: {
            title: 'Price Tier - Edit',
            roles: roles.Inventory_SectionRoles_Section,
            icon: 'price-tier',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'LocationBins',
          path: 'bins',
          component: () => import('@/views/inventory/location-bins'),
          meta: { title: 'Location Bins', roles: roles.Inventory_SectionRoles_Section, icon: 'bins', noCache: true }
        },
        {
          name: 'InventoryImports',
          path: 'inventory-import',
          component: () => import('@/views/inventory/import'),
          meta: { title: 'Imports', roles: roles.Inventory_SectionRoles_Section, icon: 'cloud-upload', noCache: true }
        }
      ]
    },
    {
      path: '/inventory-reports',
      component: AppMain,
      name: 'inventory-reports',
      alwaysShow: true,
      meta: {
        title: 'REPORTS',
        roles: roles.Inventory_SectionRoles_Section,
        breadcrumb: false
      },
      children: [
        {
          name: 'ProductDiff',
          path: 'reports/products-diff',
          component: () => import('@/views/inventory/reports/products-diff'),
          meta: {
            title: 'Products Difference',
            icon: 'file-spreadsheet',
            noCache: true
          }
        },
        {
          name: 'ProductList',
          path: 'reports/products-list',
          component: () => import('@/views/inventory/reports/products-list'),
          meta: {
            title: 'Products List',
            icon: 'file-spreadsheet',
            noCache: true
          }
        },
        {
          name: 'InventoryMovementsDetails',
          path: 'reports/inventory-movements-details',
          component: () => import('@/views/inventory/reports/inventory-movements-details'),
          meta: {
            title: 'Inventory Movements Details',
            icon: 'file-spreadsheet',
            noCache: true
          }
        },
        {
          name: 'InventoryAging',
          path: 'reports/inventory-aging',
          component: () => import('@/views/inventory/reports/inventory-aging'),
          meta: {
            title: 'Inventory Aging',
            icon: 'file-spreadsheet',
            noCache: true
          }
        },
        {
          name: 'InventoryProductsLowStock',
          path: 'reports/inventory-products-low-stock',
          component: () => import('@/views/inventory/reports/inventory-products-low-stock'),
          meta: {
            title: 'Products low on stock',
            icon: 'file-spreadsheet',
            noCache: true
          }
        },
        {
          name: 'ProductsAvailability',
          path: 'reports/products-availability',
          component: () => import('@/views/inventory/reports/products-availability'),
          meta: {
            title: 'Products Availabilities',
            icon: 'file-spreadsheet',
            noCache: true
          }
        },
        {
          name: 'ProductsChangesReport',
          path: 'reports/products-changes',
          component: () => import('@/views/inventory/reports/products-changes'),
          meta: {
            title: 'Products Changes Report',
            icon: 'file-spreadsheet',
            noCache: true
          }
        }
      ]
    }
  ]
}

export default inventoryRouter
