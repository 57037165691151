/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
import { AppMain } from '@/layout/components'
// Roles for Permissions
import roles from './roles'

const adminRouter = {
  path: '/general',
  component: Layout,
  redirect: 'noRedirect',
  name: 'Administration',
  alwaysShow: true,
  meta: {
    title: 'Administration',
    icon: 'company',
    roles: roles.General_SectionRoles_Section
  },
  children: [
    {
      name: 'Users',
      path: 'users',
      component: () => import('@/views/general/users'),
      meta: { title: 'Users', icon: 'user' }
    },
    {
      name: 'Roles',
      path: 'roles',
      component: () => import('@/views/general/roles'),
      meta: { title: 'Roles', icon: 'roles' }
    },
    {
      name: 'ParameterTypes',
      path: 'parameter_types',
      component: () => import('@/views/general/parameter-types'),
      meta: { title: 'Parameter Types', icon: 'items' }
    },
    {
      name: 'Parameters',
      path: 'parameters',
      component: () => import('@/views/general/parameters'),
      meta: { title: 'Parameters', icon: 'parameters' }
    },
    {
      name: 'Countries',
      path: 'countries',
      component: () => import('@/views/general/countries'),
      meta: { title: 'Countries', icon: 'international' }
    },
    {
      name: 'Provinces',
      path: 'provinces',
      component: () => import('@/views/general/provinces'),
      meta: { title: 'Provinces', icon: 'provinces' }
    },
    {
      name: 'Locations',
      path: 'locations',
      component: () => import('@/views/general/locations'),
      meta: { title: 'Locations', icon: 'locations' }
    },
    {
      name: 'Currencies',
      path: 'currencies',
      component: () => import('@/views/general/currencies'),
      meta: { title: 'Currencies', icon: 'dollar' }
    },
    {
      name: 'CustomerForm',
      path: 'customer/:id?',
      component: () => import('@/views/general/customer/customer-form'),
      meta: { title: 'Customer - Edit', icon: 'peoples' },
      hidden: true
    },
    {
      name: 'customers',
      path: 'customers',
      component: () => import('@/views/general/customer/customers'),
      meta: { title: 'Customers', icon: 'peoples' }
    },
    {
      name: 'tags',
      path: 'tags',
      component: () => import('@/views/general/tags'),
      meta: { title: 'Tags', icon: 'tags' }
    },
    {
      name: 'TaxSetup',
      path: 'tax_setups',
      component: () => import('@/views/general/tax-setup/tax-setup'),
      meta: { title: 'Tax Setup', icon: 'dollar-plus' }
    },
    {
      name: 'TaxSetupForm',
      path: 'tax_setup/:id?',
      component: () => import('@/views/general/tax-setup/tax-setup-form'),
      meta: { title: 'Tax Setup - Edit', icon: 'dollar-plus' },
      hidden: true
    },
    {
      name: 'settings',
      path: 'settings',
      component: () => import('@/views/general/settings/index'),
      meta: { title: 'Settings', icon: 'settings-alt' }
    },
    {
      path: '/general-accounting',
      component: AppMain,
      name: 'general-accounting',
      alwaysShow: true,
      meta: {
        title: 'ACCOUNTING',
        roles: roles.Inventory_SectionRoles_Section,
        breadcrumb: false
      },
      children: [
        {
          name: 'ChartOfAccounts',
          path: 'chart-of-accounts',
          component: () => import('@/views/general/accounts'),
          meta: { title: 'Chart of accounts', icon: 'file-spreadsheet', noCache: true }
        },
        {
          name: 'AccountingMapping',
          path: 'accounting-mapping',
          component: () => import('@/views/general/account-mappings'),
          meta: { title: 'Accounting Mapping', icon: 'file-spreadsheet', noCache: true }
        },
        {
          path: '/general-accounting-reports',
          component: AppMain,
          name: 'general-accounting-reports',
          alwaysShow: true,
          meta: {
            title: 'REPORTS',
            roles: roles.Inventory_SectionRoles_Section,
            breadcrumb: false
          },
          children: [
            {
              name: 'ChartOfAccountsList',
              path: 'reports/chart-of-accounts-list',
              component: () => import('@/views/general/reports/chart-of-accounts-list'),
              meta: { title: 'Chart of Accounts List', icon: 'file-spreadsheet', noCache: true }
            }
          ]
        }
      ]
    }
  ]
}

export default adminRouter
