import { Notification } from 'element-ui'

const queueSize = 2

const notifyInstances = []
const resetNotify = (options) => {
  const notifyInstance = notifyInstances.length >= queueSize ? notifyInstances.shift() : null
  if (notifyInstance) {
    notifyInstance.close()
  }

  notifyInstances.push(Notification(options))
}

;['error', 'success', 'info', 'warning'].forEach((type) => {
  resetNotify[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetNotify(options)
  }
})

export const notify = resetNotify
