import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './layout/nexterp-ui' // NextERP-UI
import './permission' // permission control
import './utils/error-log' // error log

// UTILS
import { closeSelectedTab, helpers, notificationMessage, responseErrorMessage } from './utils'
import { getToken } from '@/utils/auth'
import * as filters from './filters' // global filters
// 3RD Party Libs
import VueTheMask from 'vue-the-mask'
import FormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VuexUndoRedo from './plugins/undoRedo'
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js'
import VueEcho from 'vue-echo-laravel'
import { notify } from '@/utils/reset-notify'
import { message } from '@/utils/reset-message'

Vue.use(VuexUndoRedo)

Vue.use(Element, {
  size: 'medium', // set element-ui default size
  locale
})
Vue.prototype.helper = helpers
Vue.prototype.$notify = notify
Vue.prototype.$message = message
Vue.prototype.$notificationMessage = notificationMessage
Vue.prototype.$responseErrorMessage = responseErrorMessage
Vue.prototype.$closeSelectedTab = closeSelectedTab

Vue.use(VueTheMask)
Vue.use(FormWizard)
Vue.use(require('vue-moment'))
// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false
// PUSHER
Vue.use(VueEcho, {
  broadcaster: 'pusher',
  authEndpoint: process.env.VUE_APP_BASE_API + 'broadcasting/auth',
  auth: {
    headers: {
      Authorization: getToken()
    }
  },
  key: process.env.VUE_APP_PUSHER_KEY,
  wsHost: process.env.VUE_APP_PUSHER_HOST,
  wsPort: process.env.VUE_APP_PUSHER_PORT,
  wssPort: process.env.VUE_APP_PUSHER_PORT,
  enabledTransports: ['ws', 'wss'],
  disableStats: process.env.ENV === 'production',
  forceTLS: process.env.VUE_APP_PUSHER_HTTPS === 'true',
  encrypted: true
})

Vue.use(
  new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App)
  })
)
