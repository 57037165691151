/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Roles for Permissions
import roles from './roles'

const projectsRouter = {
  path: '/projects',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ProjectsModule',
  alwaysShow: true,
  meta: {
    module: 'Projects',
    title: 'Project Management',
    icon: 'file-spreadsheet',
    roles: roles.Projects_SectionRoles_Section
  },
  children: [
    {
      name: 'Projects',
      path: '/list-projects',
      component: () => import('@/views/projects/projects'),
      meta: { title: 'Projects', icon: 'projects' }
    },
    {
      hidden: true,
      name: 'ProjectTasks',
      path: '/projects/tasks/:project_id',
      component: () => import('@/views/projects/tasks'),
      meta: { title: 'Project Tasks', icon: 'merged-order' }
    },
    {
      hidden: true,
      name: 'ProjectTaskItems',
      path: '/projects/tasks/items/:task_id',
      component: () => import('@/views/projects/task-items'),
      meta: { title: 'Project Task Items', icon: 'recipe_proposals' }
    },
    {
      hidden: true,
      name: 'ProjectTaskItemRecords',
      path: '/task_items/record/:task_item_id',
      component: () => import('@/views/projects/task-item-records'),
      meta: { title: 'Project Task Item Records', icon: 'items' }
    },
    {
      name: 'ProjectsReport',
      path: '/projects_report',
      component: () => import('@/views/projects/projects-report'),
      meta: { title: 'Projects Report', icon: 'data' }
    }
  ]
}

export default projectsRouter
