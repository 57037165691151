export { parseValues, formatDate, formatLabel } from '@/utils'

export function recipeFormat(value, digits = 2) {
  return Number(Math.trunc(value * Math.pow(10, digits)) / Math.pow(10, digits).toFixed(digits))
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

export function formatStr(value) {
  return (value || 0) + 'mg'
}

export function formatSize(value) {
  return (value || 0) + 'ml'
}

export function tags_filter(value) {
  value = typeof value === 'string' ? value.toLocaleLowerCase() : value
  switch (value) {
    case 'partially allocated':
    case 'partially received':
    case 'in progress':
    case 'not in purchase':
    case 'not in transfer':
      return 'warning'
    case 'allocated':
    case 'received':
    case 'done':
    case 'in purchase':
    case 'in transfer':
      return 'success'
    default:
      return 'info'
  }
}

/**
 * 1 = > 00001
 * @param {number} value
 */
export function percentFormat(value) {
  return value ? Math.round(parseFloat(value) * 100) / 100 : null
}

export function moneyFormat(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol'
  })

  return formatter.format(value)
}
export function costFormat(value, qty) {
  return (Number(Math.round(value * 100) / 100).toFixed(4) * qty) / 1000 //* Units are not set up for cost/amnt yet so this is a temp fix
}
export function formatBoolean(value) {
  return value ? 'Yes' : 'No'
}

export function volumeFormat(value, size = 0) {
  return value === '-' ? '-' : parseFloat((Math.round(value * 100) / 100).toFixed(size))
}

/**
 * 1 = > 00001
 * @param {number} value
 */
export function roundFormat(value) {
  return Math.round(parseFloat(value * 100)) / 100
}

export function nicotineFormat(value) {
  let result = ''
  if (value) {
    const re = new RegExp('(\\d+\\.\\d{3})(\\d)')
    const m = value.toString().match(re)
    result = m ? parseFloat(m[1]) : value.valueOf()
  }

  return result
}

/**
 * 1 = > 00001
 * @param {number} value
 */
export function sizeFormat(value, size = 2) {
  return parseFloat(value) % 1 === 0 ? parseFloat(value) : parseFloat(value).toFixed(size)
}

/**
 * 1 = > 00001
 * @param {number} number
 */
export function zeroFill(number) {
  let width = 5
  width -= number.toString().length
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
  }
  return number + '' // always return a string
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

export function formatCurrency(value) {
  if (value) {
    const val = (value / 1).toFixed(2).replace(',', '.')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return (0).toFixed(2)
  }
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function formatMsgError(field, errors) {
  return field in errors ? errors[field].msg : ''
}

export function removeTrailingZeros(number) {
  return number != null ? number.toString() * 1 : null
}
