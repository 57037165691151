<!-- eslint-disable vue/no-v-html -->
<template>
  <el-table-column :align="align" :prop="prop" :width="width" :class-name="className" :fixed="fixed">
    <template #header>
      <el-popover v-model="filterVisible" placement="bottom-start" width="230" trigger="manual">
        <el-row style="margin-bottom: 10px">
          <el-select v-model="searchOperator" placeholder="Select" @change="handleOperator">
            <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-row>
        <el-row>
          <el-row v-for="(filter, index) in filters" :key="index">
            <el-radio-group
              v-if="index !== 0"
              v-model="filter.logic_operator"
              style="margin-bottom: 10px"
              @change="doCustomFilter(filter)">
              >
              <el-radio v-for="logicOperator in logicOperators" :key="logicOperator.value" :label="logicOperator.value">
                {{ logicOperator.label }}
              </el-radio>
            </el-radio-group>

            <el-select
              v-if="searchOperator === 'custom'"
              v-model="filter.operator"
              placeholder="Select"
              style="margin-bottom: 10px"
              @change="doCustomFilter(filter)">
              <el-option
                v-for="(item, selectIndex) in customOptions"
                :key="selectIndex"
                :label="item.label"
                :value="item.value" />
            </el-select>

            <el-radio-group
              v-if="columnsOptionsList.length > 0"
              v-model="filter.value_type"
              style="margin-bottom: 10px"
              @change="clearValue(filter)">
              >
              <el-radio v-for="valueType in valueTypes" :key="valueType.value" :label="valueType.value">
                {{ valueType.label }}
              </el-radio>
            </el-radio-group>

            <el-input
              v-if="filter.value_type === 'value'"
              v-model="filter.value"
              placeholder="Search..."
              clearable
              style="margin-bottom: 10px"
              @change="doFilter" />

            <el-select
              v-if="filter.value_type === 'column'"
              v-model="filter.value"
              placeholder="Select"
              clearable
              style="margin-bottom: 10px"
              @change="doFilter"
              @clear="doFilter">
              <el-option
                v-for="(column, selectIndex) in columnsOptionsList"
                :key="selectIndex"
                :label="column.label"
                :value="column.value" />
            </el-select>
          </el-row>
        </el-row>
        <el-row>
          <el-row
            v-if="optionsList.length > 0"
            v-infinite-scroll="loadMore"
            class="infinite-list"
            style="max-height: 250px; overflow-y: scroll">
            <el-checkbox-group v-model="selectedOptions" @change="doFilter">
              <el-row v-for="(item, index) in getOptionsList()" :key="index" class="infinite-list-item">
                <el-checkbox :label="item" />
              </el-row>
            </el-checkbox-group>
          </el-row>
          <el-divider />
          <div style="text-align: right; margin: 0">
            <el-button type="text" size="mini" @click="resetColumnFilter">Reset</el-button>
            <el-button type="primary" size="mini" @click="filterVisible = false">Update</el-button>
          </div>
        </el-row>
        <span slot="reference">
          {{ label }}
          <el-link @click="filterVisible = !filterVisible">
            <svg-icon :icon-class="getCaretClass()" />
          </el-link>
        </span>
      </el-popover>
      <el-link v-if="sortable" :class="getSortClass()" @click="sort">
        <em :class="sortIconClass" />
      </el-link>
    </template>
    <template #default="{ row }">
      <span v-html="valueFilter(row, prop)" />
    </template>
  </el-table-column>
</template>
<script>
import { deepCopy } from '@/utils'
export default {
  props: {
    label: {
      type: String,
      default() {
        return ''
      }
    },
    width: {
      type: String,
      default() {
        return null
      }
    },
    align: {
      type: String,
      default() {
        return 'right'
      }
    },
    prop: {
      type: String,
      default() {
        return null
      }
    },
    sortable: {
      type: Boolean,
      default() {
        return true
      }
    },
    className: {
      type: String,
      default() {
        return null
      }
    },
    fixed: {
      type: Boolean,
      default() {
        return false
      }
    },
    fieldType: {
      type: String,
      default() {
        return 'string'
      }
    },
    tableFilter: {
      type: Object,
      default() {
        return {}
      }
    },
    optionsList: {
      type: Array,
      default() {
        return []
      }
    },
    optionsFetchFunction: {
      type: Function,
      default() {
        return null
      }
    },
    tableColumnsOptionsList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      filterVisible: false,
      searchOperator: '=',
      filters: [],
      logicOperators: [],
      valueTypes: [],
      selectedOptions: [],
      options: [],
      customOptions: [],
      sortFilter: null
    }
  },
  computed: {
    sortIconClass: function () {
      let iconClass = 'action-icon '
      if (!this.sortFilter) {
        iconClass += 'el-icon-sort'
      } else {
        iconClass += this.sortFilter.direction === 'asc' ? 'el-icon-sort-up' : 'el-icon-sort-down'
      }
      return iconClass
    },
    isFiltered: function () {
      let isFiltered = false
      this.filters.forEach((filter) => {
        if (filter.value !== '') {
          isFiltered = true
        }
      })
      return isFiltered
    },
    columnsOptionsList: function () {
      const columnsOptionsList = []
      this.tableColumnsOptionsList.forEach((column) => {
        if (column.key !== this.prop) {
          columnsOptionsList.push({ value: column.key, label: column.label })
        }
      })
      return columnsOptionsList
    }
  },
  watch: {
    tableFilter: {
      deep: true,
      handler(newVal) {
        // reset orderBy if another column is ordering
        if (newVal.order_by && newVal.order_by.field !== this.prop) {
          this.sortFilter = null
        }
      }
    }
  },
  mounted() {
    this.setSelectedFilter()

    this.logicOperators = [
      { label: 'AND', value: 'and' },
      { label: 'OR', value: 'or' }
    ]

    this.valueTypes = [
      { label: 'VALUE', value: 'value' },
      { label: 'COLUMN', value: 'column' }
    ]

    this.customOptions = [
      { label: 'Equals', value: '=' },
      { label: 'Not equal to', value: '<>' }
    ]

    if (this.fieldType === 'string') {
      this.customOptions = this.customOptions.concat([
        { label: 'Contains', value: 'contains' },
        { label: 'Does not contain', value: 'not_contains' }
      ])

      this.options = this.customOptions.concat([{ label: 'Custom', value: 'custom' }])
    }

    if (this.fieldType !== 'string') {
      this.customOptions = this.customOptions.concat([
        { label: 'Less than', value: '<' },
        { label: 'Less than or equal to', value: '<=' },
        { label: 'Greater than', value: '>' },
        { label: 'Greater than or equal to', value: '>=' }
      ])

      this.options = this.customOptions.concat([
        { label: 'Between', value: 'between' },
        { label: 'Custom', value: 'custom' }
      ])
    }

    this.resetFilters()
  },
  methods: {
    valueFilter(row, prop) {
      let value = ''
      if (prop.includes('.')) {
        const keys = prop.split('.')
        value = row[keys[0]][keys[1]] || row[keys[0]][keys[1]] === 0 ? row[keys[0]][keys[1]] : ''
      } else {
        value = row[prop] || row[prop] === 0 ? row[prop] : ''
      }

      if (value && value.toString().includes('|')) {
        return value.toString().replace('|', '<br>')
      } else {
        return value
      }
    },
    loadMore() {
      if (this.optionsFetchFunction) {
        // console.log('loadMore: ')
      }
    },
    sort() {
      if (!this.sortFilter) {
        this.sortFilter = { field: this.prop, direction: 'asc' }
      } else if (this.sortFilter.direction === 'asc') {
        this.sortFilter = { field: this.prop, direction: 'desc' }
      } else {
        this.sortFilter = null
      }
      this.$emit('sort', this.sortFilter)
    },
    doFilter() {
      this.$emit('filter', { field: this.prop, field_type: this.fieldType, filters: { ...this.filters } })
    },
    doCustomFilter(filter) {
      if (filter.value !== '') {
        this.doFilter()
      }
    },
    getOptionsList() {
      return this.optionsList
    },
    handleOperator() {
      const emitFilter = this.isFiltered
      const originalFilters = deepCopy(this.filters)

      if (this.searchOperator === 'between') {
        this.searchOperator = 'custom'
        this.filters = [
          {
            logic_operator: 'and',
            operator: '>=',
            value_type: originalFilters[0].value_type,
            value: originalFilters[0].value
          },
          {
            logic_operator: 'and',
            operator: '<=',
            value_type: originalFilters[1] ? originalFilters[1].value_type : 'value',
            value: originalFilters[1] ? originalFilters[1].value : ''
          }
        ]
      } else if (this.searchOperator === 'custom') {
        this.filters = [
          {
            logic_operator: 'and',
            operator: '=',
            value_type: originalFilters[0].value_type,
            value: originalFilters[0].value
          },
          {
            logic_operator: 'and',
            operator: '=',
            value_type: originalFilters[1] ? originalFilters[1].value_type : 'value',
            value: originalFilters[1] ? originalFilters[1].value : ''
          }
        ]
      } else {
        this.resetFilters()
        this.filters[0].operator = this.searchOperator
        this.filters[0].value_type = originalFilters[0].value_type
        this.filters[0].value = originalFilters[0].value
      }

      if (emitFilter) {
        this.doFilter()
      }
    },
    resetFilters() {
      this.filters = [
        {
          logic_operator: 'and',
          operator: '=',
          value_type: 'value',
          value: ''
        }
      ]
    },
    clearValue(filter) {
      if (filter.value) {
        filter.value = ''
        this.doFilter()
      }
    },
    resetColumnFilter() {
      this.searchOperator = '='
      this.resetFilters()
      this.setSelectedFilter()
      this.doFilter()
      this.filterVisible = false
      this.sortFilter = null
    },
    setSelectedFilter() {
      if (this.optionsList.length > 0) {
        this.selectedOptions = this.optionsList
      }
    },
    getCaretClass() {
      return this.isFiltered ? 'adv_filter' : 'adv_caret'
    },
    getSortClass() {
      return this.sortFilter ? 'filters-on' : ''
    }
  }
}
</script>
