import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles, modules) {
  const filteredByModule = []
  let filteredByRoles = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (!('meta' in route) || !('module' in route.meta) || (modules && modules.includes(route.meta.module))) {
      filteredByModule.push(tmp)
    }
  })

  if (roles.length > 0) {
    filteredByModule.forEach((route) => {
      const tmp = { ...route }
      if (hasPermission(roles, tmp)) {
        if (tmp.children) {
          tmp.children = filterAsyncRoutes(tmp.children, roles, modules)
        }
        filteredByRoles.push(tmp)
      }
    })
  } else {
    filteredByRoles = filteredByModule
  }

  return filteredByRoles
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, data) {
    return new Promise((resolve) => {
      let accessedRoutes

      if (data.roles.includes('admin')) {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, [], data.modules) || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, data.roles, data.modules)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
