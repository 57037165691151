<template>
  <div>
    <vue-element-loading
      :active="loading"
      is-full-screen
      background-color="#FFF"
      text="loading..."
      :text-style="{ color: 'grey', margin: '-40px 0 0 0' }">
      <img :src="loadingGif" width="150px" height="150px" alt="loading" />
    </vue-element-loading>
  </div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import loadingFile from '@/assets/loading.gif'
import { mapGetters } from 'vuex'

export default {
  name: 'AppLoading',
  components: {
    VueElementLoading
  },
  data() {
    return {
      loadingGif: loadingFile,
      startTime: null
    }
  },
  computed: {
    ...mapGetters(['loading'])
  },
  watch: {
    loading: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          setTimeout(() => {
            this.$store.dispatch('app/stopLoading')
          }, 120000)
        }
      }
    }
  }
}
</script>
