/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Roles for Permissions
import roles from './roles'

const inventoryRouter = {
  path: '/pur',
  component: Layout,
  redirect: 'noRedirect',
  name: 'PurchaseModule',
  alwaysShow: true,
  meta: {
    module: 'Purchase',
    title: 'Purchase',
    icon: 'orders',
    roles: roles.Purchase_SectionRoles_Section
  },
  children: [
    {
      name: 'Purchases',
      path: '/purchases',
      component: () => import('@/views/purchases/purchases'),
      meta: { title: 'Purchases', icon: 'recipe-ingredients' }
    },
    {
      name: 'PurchaseForm',
      path: '/purchase/:id?',
      component: () => import('@/views/purchases/purchase-form'),
      meta: { title: 'Purchase - Form', icon: 'purchase' },
      hidden: true
    },
    {
      name: 'Invoices',
      path: '/invoices',
      component: () => import('@/views/purchases/invoices/invoices'),
      meta: { title: 'Invoices', icon: 'invoice' }
    },
    {
      name: 'InvoiceCreate',
      path: '/purchase/:purchase_id/invoice',
      component: () => import('@/views/purchases/invoices/invoice-form'),
      meta: { title: 'Invoice Create' },
      hidden: true
    },
    {
      name: 'InvoiceEdit',
      path: '/invoice/:invoice_id/',
      component: () => import('@/views/purchases/invoices/invoice-form'),
      meta: { title: 'Invoice Edit' },
      hidden: true
    },
    {
      name: 'Suppliers',
      path: '/suppliers',
      component: () => import('@/views/purchases/suppliers'),
      meta: { title: 'Suppliers', icon: 'peoples' }
    },
    {
      name: 'SupplierForm',
      path: '/supplier/:id',
      component: () => import('@/views/purchases/supplier-form'),
      meta: { title: 'Supplier - Edit', icon: 'peoples' },
      hidden: true
    },
    {
      name: 'SupplierFormCreate',
      path: '/supplier/:id?',
      component: () => import('@/views/purchases/supplier-form'),
      meta: { title: 'Supplier - Create', icon: 'peoples' },
      hidden: true
    },
    {
      name: 'PurchaseImports',
      path: '/purchases/import',
      component: () => import('@/views/purchases/import'),
      meta: { title: 'Import', icon: 'cloud-upload' }
    }
  ]
}

export default inventoryRouter
