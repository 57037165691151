import axios from 'axios'
import { MessageBox } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import qs from 'qs'
import router from '@/router'
import { helpers } from '@/utils'

const timeout = process.env.VUE_APP_TIMEOUT || 1200000
axios.defaults.timeout = timeout

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout, // request timeout
  paramsSerializer: function (params) {
    for (const x in params) {
      if (typeof params[x] === 'boolean') {
        params[x] = params[x] ? 1 : 0 // force integer
      }
    }
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

function fixBooleans(object) {
  for (const x in object) {
    if (typeof object[x] === 'boolean') {
      object[x] = object[x] ? 1 : 0
    }

    if (typeof object[x] === 'object') {
      fixBooleans(object[x])
    }
  }
}

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.data && config.data.custom_token) {
      config.headers.Authorization = config.data.custom_token
      delete config.data.custom_token
    } else if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.Authorization = getToken()
    }

    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data

    if (helpers.getFilledInfo(res, 'data')) {
      if (res.data.constructor === Array) {
        res.data.map((e) => fixBooleans(e))
      } else {
        fixBooleans(res.data)
      }
    }

    // if the custom code is not 20000, it is judged as an error.
    if (!res.status && res.message === 'not_authorized') {
      // to re-login
      MessageBox.confirm('Expired session', 'Go to login?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        location.reload()
      })
    } else {
      return res
    }
  },
  (error) => {
    if (typeof error.response === 'undefined') {
      MessageBox.confirm('Server error', 'Reload the page?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        location.reload()
      })
    } else if (error.response.data.message === 'not_authorized' && router.currentRoute.path !== '/') {
      MessageBox.confirm('Expired session', 'Go to login?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        location.reload()
      })
    } else if (error.response.data.message === 'db_error') {
      MessageBox.confirm('Database error', 'Reload the page?', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        location.reload()
      })
    } else {
      return error.response.data
    }
  }
)

export default service
