/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Layout without Header
import { AppMain } from '@/layout/components'
// Roles for Permissions
import roles from './roles'

// Main Menu Item
const assemblyRouter = {
  path: '/assembly',
  component: Layout,
  redirect: 'noRedirect',
  name: 'AssemblyModule',
  alwaysShow: true,
  meta: {
    module: 'Assembly',
    title: 'Assembly',
    icon: 'assembly',
    roles: [
      ...roles.Assembly_Section_Roles_Main,
      ...roles.Assembly_Page_Roles_Assembly_Orders,
      ...roles.Assembly_Dashboard_Roles,
      ...roles.Assembly_Shipping_Dashboard_Roles
    ]
  },
  children: [
    {
      path: '/assembly-main',
      component: AppMain, // No header since subheading
      name: 'assembly-main',
      alwaysShow: true,
      meta: {
        title: 'MAIN', // label of subheading
        roles: [...roles.Assembly_Section_Roles_Main], // roles are unions of all children
        breadcrumb: false // do not affect url/path
      },
      children: [
        {
          name: 'AssemblyFlows',
          path: 'flows',
          component: () => import('@/views/assembly/admin/flows'),
          meta: { title: 'Flows', icon: 'flows', noCache: true, roles: roles.Assembly_Section_Roles_Main }
        },
        {
          name: 'AssemblyFlowActions',
          hidden: true,
          path: 'flows/:flow_id/flow-actions',
          component: () => import('@/views/assembly/admin/flow-actions'),
          meta: {
            title: 'Flow Actions',
            icon: 'flow-actions',
            noCache: true,
            roles: roles.Assembly_Section_Roles_Main
          }
        },
        {
          name: 'AssemblyOperations',
          path: 'operations',
          component: () => import('@/views/assembly/admin/operations'),
          meta: { title: 'Operations', icon: 'operations', noCache: true, roles: roles.Assembly_Section_Roles_Main }
        },
        {
          name: 'AssemblyPhases',
          path: 'phases',
          component: () => import('@/views/assembly/admin/phases'),
          meta: { title: 'Phases', icon: 'phases', noCache: true, roles: roles.Assembly_Section_Roles_Main }
        },
        {
          name: 'AssemblyMachines',
          path: 'machines',
          component: () => import('@/views/assembly/admin/machines'),
          meta: { title: 'Machines', icon: 'machines', noCache: true, roles: roles.Assembly_Section_Roles_Main }
        },
        {
          name: 'AssemblyReels',
          path: 'reels',
          component: () => import('@/views/assembly/admin/reels'),
          meta: { title: 'Reels', icon: 'reels', noCache: true, roles: roles.Assembly_Section_Roles_Main }
        },
        {
          name: 'AssemblyReelForm',
          path: 'reel/:id?',
          component: () => import('@/views/assembly/admin/components/reel-form'),
          meta: { title: 'Reel Form', icon: 'reels', noCache: true, roles: roles.Assembly_Section_Roles_Main },
          hidden: true
        },
        {
          name: 'AssemblySkids',
          path: 'skids',
          component: () => import('@/views/assembly/admin/skids'),
          meta: { title: 'Skids', icon: 'truck', noCache: true, roles: roles.Assembly_Section_Roles_Main }
        },
        {
          name: 'AssemblySettings',
          path: 'settings',
          component: () => import('@/views/assembly/admin/settings/index'),
          meta: { title: 'Settings', icon: 'settings', roles: roles.Assembly_Section_Roles_Main }
        }
      ]
    },
    {
      path: '/assembly-order',
      component: AppMain,
      name: 'assembly-order',
      alwaysShow: true,
      meta: {
        title: 'ORDERS',
        roles: [...roles.Assembly_Page_Roles_Assembly_Orders],
        breadcrumb: false
      },
      children: [
        {
          name: 'AssemblyOrders',
          path: 'assembly-orders',
          component: () => import('@/views/assembly/admin/assembly-orders'),
          meta: {
            title: 'Assembly Orders',
            icon: 'production-orders',
            noCache: true,
            roles: roles.Assembly_Page_Roles_Assembly_Orders
          }
        }
      ]
    },
    {
      path: '/assembly-dashboards',
      component: AppMain,
      name: 'assembly-dashboards',
      alwaysShow: true,
      meta: {
        title: 'DASHBOARDS',
        roles: [...roles.Assembly_Dashboard_Roles, ...roles.Assembly_Shipping_Dashboard_Roles],
        breadcrumb: false
      },
      children: [
        {
          name: 'AssemblyDashboard',
          path: 'assembly-dashboard',
          component: () => import('@/views/assembly/assembly-orders/assembly-dashboard'),
          meta: {
            title: 'Assembly Dashboard',
            icon: 'dashboard',
            noCache: true,
            roles: roles.Assembly_Dashboard_Roles
          }
        },
        {
          name: 'AssemblyShippingDashboard',
          path: 'shipping-dashboard',
          component: () => import('@/views/assembly/assembly-orders/shipping-dashboard'),
          meta: {
            title: 'Shipping Dashboard',
            icon: 'dashboard',
            noCache: true,
            roles: roles.Assembly_Shipping_Dashboard_Roles
          }
        }
      ]
    },
    {
      path: '/assembly-reports',
      component: AppMain,
      name: 'assembly-reports',
      alwaysShow: true,
      meta: {
        title: 'REPORTS',
        roles: roles.Assembly_Page_Roles_Assembly_Orders,
        breadcrumb: false
      },
      children: [
        {
          name: 'StampingReport',
          path: 'reports/stamping',
          component: () => import('@/views/assembly/reports/stamping'),
          meta: {
            title: 'Stamping Report',
            icon: 'file-spreadsheet',
            noCache: true
          }
        },
        {
          name: 'ReelsSummaryReport',
          path: 'reports/reels-summary',
          component: () => import('@/views/assembly/reports/reels-summary'),
          meta: {
            title: 'Reels Summary Report',
            icon: 'file-spreadsheet',
            noCache: true
          }
        },
        {
          name: 'DamagedStampsReport',
          path: 'reports/damaged-stamps',
          component: () => import('@/views/assembly/reports/damaged-stamps'),
          meta: {
            title: 'Damaged Stamps Report',
            icon: 'file-spreadsheet',
            noCache: true
          }
        }
      ]
    }
  ]
}

export default assemblyRouter
