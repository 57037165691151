import { login, getInfo } from '@/api/general/users'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const state = {
  token: getToken(),
  info: [],
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, user) => {
    state.info = user
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_CONFIG: (state, config) => {
    state.info.config = config
  }
}

const actions = {
  setInfo({ commit }, info) {
    commit('SET_USER_INFO', info)
  },
  setConfig({ commit }, config) {
    commit('SET_CONFIG', config)
  },

  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password })
        .then((response) => {
          if (response.status) {
            const { data } = response
            commit('SET_TOKEN', data.access_token)
            setToken(data.token_type + ' ' + data.access_token)
            resolve()
          } else {
            reject(response.message)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          if (response.status) {
            const { data } = response
            const { roles } = data
            if (!roles || roles.length <= 0) {
              reject(new Error('getInfo: roles must be a non-null array!'))
            } else {
              commit('SET_ROLES', roles)
              commit('SET_USER_INFO', data)

              resolve(data)
            }
          } else {
            reject(response.message)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()

      // reset visited views and cached views
      // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
      dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
