import { find } from '@/api/production/hplc/hplc-ranges'

const state = {
  ranges: []
}

const getters = {
  ranges: (state) => {
    return state.ranges.reduce(
      (acc, range) => {
        switch (range.type) {
          case 1:
            acc.minRange = range.percent_range
            break
          case 2:
            acc.maxRange = range.percent_range
            break
        }

        return acc
      },
      {
        minRange: null,
        maxRange: null
      }
    )
  }
}

// mutation-types
export const SET_HPLC_RANGES = 'setHplcRanges'

const mutations = {
  [SET_HPLC_RANGES](state, { ranges }) {
    state.ranges = ranges
  }
}

const actions = {
  getHplcRanges({ commit }) {
    commit(SET_HPLC_RANGES, { ranges: [] })
    find().then((response) => {
      if (response.status) {
        commit(SET_HPLC_RANGES, { ranges: response.data })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
