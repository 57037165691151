/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Layout without Header
import { AppMain } from '@/layout/components'
// Roles for Permissions
import roles from './roles'

// Main Menu Item
const productionRouter = {
  path: '/production',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ProductionModule',
  alwaysShow: true,
  meta: {
    module: 'Production',
    title: 'Production',
    icon: 'productions',
    roles: [
      ...roles.Production_SectionRoles_Main,
      ...roles.Production_PageRoles_Dashboard,
      ...roles.Production_PageRoles_Transfer,
      ...roles.Production_PageRoles_ProductionOrder,
      ...roles.Production_PageRoles_DailyOrder,
      ...roles.Production_PageRoles_Pending,
      ...roles.Production_PageRoles_Production,
      ...roles.Production_PageRoles_Admin,
      ...roles.Production_SectionRoles_Recipes,
      ...roles.Production_SectionRoles_Report,
      ...roles.reelsPagesRoles,
      ...roles.productStampingOrderCorrectionsRoles,
      ...roles.prodOrdersCorrectionsRoles
    ]
  },
  children: [
    {
      path: '/production-main',
      component: AppMain, // No header since subheading
      name: 'production-main',
      alwaysShow: true,
      meta: {
        title: 'MAIN', // label of subheading
        roles: [
          ...roles.Production_SectionRoles_Main,
          ...roles.reelsPagesRoles,
          ...roles.Production_SectionRoles_Recipes,
          ...roles.productStampingOrderCorrectionsRoles,
          ...roles.prodOrdersCorrectionsRoles
        ], // roles are unions of all children
        breadcrumb: false // do not affect url/path
      },
      children: [
        {
          name: 'Flows',
          path: 'flows',
          component: () => import('@/views/production/admin/flows'),
          meta: { title: 'Flows', icon: 'flows', noCache: true, roles: roles.Production_SectionRoles_Main }
        },
        {
          name: 'FlowActions',
          hidden: true,
          path: 'flows/:flow_id/flow-actions',
          component: () => import('@/views/production/admin/flow-actions'),
          meta: {
            title: 'Flow Actions',
            icon: 'flow-actions',
            noCache: true,
            roles: roles.Production_SectionRoles_Main
          }
        },
        {
          name: 'Operations',
          path: 'operations',
          component: () => import('@/views/production/admin/operations'),
          meta: { title: 'Operations', icon: 'operations', noCache: true, roles: roles.Production_SectionRoles_Main }
        },
        {
          name: 'Phases',
          path: 'phases',
          component: () => import('@/views/production/admin/phases'),
          meta: { title: 'Phases', icon: 'phases', noCache: true, roles: roles.Production_SectionRoles_Main }
        },
        {
          name: 'Machines',
          path: 'machines',
          component: () => import('@/views/production/admin/machines'),
          meta: { title: 'Machines', icon: 'machines', noCache: true, roles: roles.Production_SectionRoles_Main }
        },
        {
          name: 'Stamping Machines',
          path: 'stamping-machines',
          component: () => import('@/views/production/admin/stamping-machines'),
          meta: {
            title: 'Stamping Machines',
            icon: 'machines',
            noCache: true,
            roles: roles.Production_SectionRoles_Main
          }
        },
        {
          name: 'Reels',
          path: 'reels',
          component: () => import('@/views/production/admin/reels'),
          meta: { title: 'Reels', icon: 'reels', noCache: true, roles: roles.reelsPagesRoles }
        },
        {
          name: 'ReelForm',
          path: 'reel/:id?',
          component: () => import('@/views/production/admin/components/reel-form'),
          meta: { title: 'Reel Form', icon: 'reels', noCache: true, roles: roles.reelsPagesRoles },
          hidden: true
        },
        {
          name: 'Carts',
          path: 'carts',
          component: () => import('@/views/production/transfer/transfer-carts'),
          meta: { title: 'Carts', icon: 'carts', noCache: true, roles: roles.Production_SectionRoles_Main }
        },
        {
          name: 'Skids',
          path: 'skids',
          component: () => import('@/views/production/transfer/transfer-skids'),
          meta: { title: 'Skids', icon: 'truck', noCache: true, roles: roles.Production_SectionRoles_Main }
        },
        {
          name: 'HplcCategories',
          path: 'hplc-categories',
          component: () => import('@/views/production/hplc/hplc-categories'),
          meta: { title: 'Hplc Categories', icon: 'truck', noCache: true, roles: roles.Production_SectionRoles_Main }
        },
        {
          name: 'RecipesAdmin',
          path: 'recipes',
          component: () => import('@/views/production/recipes/recipes'),
          meta: {
            title: 'Manage recipes',
            icon: 'manage-recipes',
            roles: roles.Production_SectionRoles_Recipes,
            noCache: true
          }
        },
        {
          name: 'RecipesImports',
          path: '/production/import',
          component: () => import('@/views/production/recipes/import'),
          meta: {
            title: 'Recipe Imports',
            icon: 'cloud-upload',
            roles: roles.Production_PageRoles_Imports_Recipes,
            noCache: true
          }
        },
        {
          name: 'RecipeFetch',
          path: '/recipe/fetch',
          component: () => import('@/views/production/recipes/recipe-fetch'),
          meta: {
            title: 'Check Recipe',
            roles: roles.Production_PageRoles_Imports_Recipes,
            icon: 'manage-recipes',
            noCache: true
          },
          hidden: true
        },
        {
          name: 'ProductionSettings',
          path: 'settings',
          component: () => import('@/views/production/admin/settings/index'),
          meta: { title: 'Settings', icon: 'settings', roles: roles.Production_SectionRoles_Main }
        }
      ]
    },
    {
      path: '/distribution-centers',
      component: AppMain,
      name: 'distribution-centers',
      alwaysShow: true,
      meta: {
        title: 'DISTRIBUTION CENTERS',
        roles: [...roles.warehouseProductsLocationsRoles],
        breadcrumb: false
      },
      children: [
        {
          name: 'WarehouseProductsLocations',
          path: 'warehouse-products-locations',
          component: () => import('@/views/production/admin/warehouse-products-locations'),
          meta: {
            title: 'Warehouse Products Locations',
            icon: 'bins',
            noCache: true,
            roles: roles.warehouseProductsLocationsRoles
          }
        },
        {
          name: 'WarehouseProductLocationsForm',
          path: 'warehouse-product-locations/:product_id?',
          component: () => import('@/views/production/admin/components/warehouse-product-locations-form'),
          meta: {
            title: 'Warehouse Product Locations Form',
            icon: 'bins',
            noCache: true,
            roles: roles.warehouseProductsLocationsRoles
          },
          hidden: true
        },
        {
          name: 'WarehouseProductLocationsLogs',
          path: 'warehouse-product-locations-logs/:product_id',
          component: () => import('@/views/production/admin/components/warehouse-product-locations-logs'),
          meta: {
            title: 'Warehouse Product Locations Logs',
            icon: 'bins',
            noCache: true,
            roles: roles.warehouseProductsLocationsRoles
          },
          hidden: true
        },
        {
          path: 'reports',
          component: AppMain,
          name: 'distribution-centers-reports',
          alwaysShow: true,
          meta: {
            title: 'REPORTS',
            roles: [...roles.warehouseProductsLocationsRoles],
            breadcrumb: false
          },
          children: [
            {
              name: 'WarehouseProductsLocationsLogsReport',
              path: 'warehouse-products-locations-logs',
              component: () => import('@/views/production/reports/warehouse-products-locations-logs-report'),
              meta: {
                title: 'Warehouse Products Locations Logs',
                icon: 'file-spreadsheet',
                noCache: true,
                roles: roles.warehouseProductsLocationsRoles
              }
            },
            {
              name: 'DuplicatedWarehouseLocations',
              path: 'duplicated-warehouse-locations',
              component: () => import('@/views/production/reports/duplicated-warehouse-locations'),
              meta: {
                title: 'Duplicated Warehouse Locations',
                icon: 'file-spreadsheet',
                noCache: true,
                roles: roles.warehouseProductsLocationsRoles
              }
            }
          ]
        }
      ]
    },
    {
      path: '/production-dashboards',
      component: AppMain,
      name: 'production-dashboards',
      alwaysShow: true,
      meta: {
        title: 'DASHBOARDS',
        roles: [...roles.Production_PageRoles_Dashboard, ...roles.Production_PageRoles_Transfer],
        breadcrumb: false
      },
      children: [
        {
          name: 'ProductionDashboard',
          path: 'production-dashboard',
          component: () => import('@/views/production/production-orders/users-dashboard'),
          meta: {
            title: 'Production Dashboard',
            icon: 'dashboard',
            noCache: true,
            roles: roles.Production_PageRoles_Dashboard
          }
        },
        {
          name: 'ProductionTransfer',
          path: 'transfer',
          component: () => import('@/views/production/transfer/transfers'),
          meta: {
            title: 'Transfer Dashboard',
            icon: 'dashboard',
            noCache: true,
            roles: roles.Production_PageRoles_Transfer
          }
        },
        {
          name: 'ProductionShipping',
          path: 'shipping',
          component: () => import('@/views/production/transfer/shippings'),
          meta: {
            title: 'Shipping Dashboard',
            icon: 'dashboard',
            noCache: true,
            roles: roles.Production_PageRoles_Transfer
          }
        },
        {
          name: 'MachinesDashboard',
          path: 'machines-dashboard',
          component: () => import('@/views/production/admin/machines-dashboard'),
          meta: {
            title: 'Machines Dashboard',
            icon: 'orders-create',
            noCache: true,
            roles: roles.Production_PageRoles_Dashboard
          }
        }
      ]
    },
    {
      path: '/production-order',
      component: AppMain,
      name: 'production-order',
      alwaysShow: true,
      meta: {
        title: 'ORDERS',
        roles: [
          ...roles.Production_PageRoles_ProductionOrder,
          ...roles.Production_PageRoles_DailyOrder,
          ...roles.Production_PageRoles_Pending,
          ...roles.Production_PageRoles_Production,
          ...roles.Production_PageRoles_Admin,
          ...roles.productStampingOrderCorrectionsRoles,
          ...roles.prodOrdersCorrectionsRoles,
          ...roles.transferQRCodeGenerationRoles
        ],
        breadcrumb: false
      },
      children: [
        {
          name: 'ProductionOrders',
          path: 'production-orders',
          component: () => import('@/views/production/admin/production-orders'),
          meta: {
            title: 'Production Orders',
            icon: 'production-orders',
            noCache: true,
            roles: roles.Production_PageRoles_ProductionOrder
          }
        },
        {
          name: 'TransferQRCodeGeneration',
          path: 'transfer_qr_code_generation',
          component: () => import('@/views/production/transfer/transfer-qr-code-form'),
          meta: {
            title: 'Transfer QR Code Generation',
            icon: 'qr-code',
            noCache: true,
            roles: roles.transferQRCodeGenerationRoles
          }
        },
        {
          name: 'DailyOrders',
          path: 'daily-orders',
          component: () => import('@/views/production/daily-orders/index'),
          meta: {
            title: 'Daily Orders',
            icon: 'daily-orders',
            noCache: true,
            roles: roles.Production_PageRoles_DailyOrder
          }
        },
        {
          name: 'PendingOrders',
          path: 'pending-orders',
          component: () => import('@/views/production/admin/pending-po'),
          meta: {
            title: 'Pending Orders',
            icon: 'input-hplc',
            noCache: true,
            roles: roles.Production_PageRoles_Pending
          }
        },
        {
          name: 'PendingReelApprovals',
          path: 'pending-reel-approvals',
          component: () => import('@/views/production/admin/pending-reels-approval'),
          meta: {
            title: 'Pending Reel Approval',
            icon: 'input-hplc',
            noCache: true,
            roles: roles.Production_PageRoles_Pending_Reel
          }
        },
        {
          name: 'ProductionOrdersCorrections',
          path: 'production_orders_corrections',
          component: () => import('@/views/production/admin/production-orders-corrections'),
          meta: {
            title: 'Production Orders Corrections',
            icon: 'search-recipes',
            noCache: true,
            roles: roles.prodOrdersCorrectionsRoles
          }
        },
        {
          name: 'Recoveries',
          path: 'recoveries',
          component: () => import('@/views/production/recoveries/index'),
          meta: { title: 'Recoveries', icon: 'recoveries', noCache: true, roles: roles.Production_PageRoles_Admin }
        },
        {
          name: 'HplcResults',
          path: 'hplc-results',
          component: () => import('@/views/production/hplc/hplc-results'),
          meta: { title: 'HPLC Results', icon: 'flows', noCache: true, roles: roles.Production_PageRoles_Hplc }
        },
        {
          name: 'HplcRequests',
          path: 'hplc-requests',
          component: () => import('@/views/production/hplc/raw-material/index'),
          meta: { title: 'HPLC Raw Materials', icon: 'hplc', noCache: true, roles: roles.Production_PageRoles_Hplc }
        },
        {
          name: 'HplcOrders',
          path: 'hplc-orders',
          component: () => import('@/views/production/hplc/orders/index'),
          meta: { title: 'HPLC Orders', icon: 'hplc', noCache: true, roles: roles.Production_PageRoles_Hplc }
        },
        {
          name: 'ProductStampingOrders',
          path: 'product-stamping-orders',
          component: () => import('@/views/production/product-stamping-orders/index'),
          meta: {
            title: 'Product Stamping Orders',
            icon: 'stamp',
            noCache: true,
            roles: roles.Production_PageRoles_Product_Stamping_Orders
          }
        },
        {
          name: 'ProductStampingOrderForm',
          path: '/product-stamping-orders/form',
          component: () => import('@/views/production/product-stamping-orders/form'),
          meta: { title: 'Products Stamping Orders', icon: 'stamp', noCache: true },
          hidden: true
        },
        {
          name: 'ProductStampingOrderStart',
          path: '/product-stamping-orders/start/:id?',
          component: () => import('@/views/production/product-stamping-orders/start'),
          meta: { title: 'Products Stamping Orders - Start', icon: 'stamp', noCache: true },
          hidden: true
        },
        {
          name: 'ProductStampingOrderFinish',
          path: '/product-stamping-orders/finish/:id?',
          component: () => import('@/views/production/product-stamping-orders/finish'),
          meta: { title: 'Products Stamping Orders - Finish', icon: 'stamp', noCache: true },
          hidden: true
        },
        {
          name: 'ProductStampingOrderCorrections',
          path: 'product-stamping-order-corrections',
          component: () => import('@/views/production/admin/product-stamping-order-corrections'),
          meta: {
            title: 'Product Stamping Order Corrections',
            icon: 'search-recipes',
            noCache: true,
            roles: roles.productStampingOrderCorrectionsRoles
          }
        }
      ]
    },
    {
      path: '/production-reports',
      component: AppMain,
      name: 'production-reports',
      alwaysShow: true,
      meta: {
        title: 'REPORTS',
        roles: [
          ...roles.Production_SectionRoles_Report,
          ...roles.prodOrdersCorrectionsRoles,
          ...roles.productionLabelCheckRoles
        ],
        breadcrumb: false
      },
      children: [
        {
          name: 'ProductionLabelCheckLogs',
          path: 'label-check-logs',
          component: () => import('@/views/production/reports/label-check-logs'),
          meta: {
            title: 'Production Label Check Logs',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.productionLabelCheckRoles
          }
        },
        {
          name: 'OrdersWithCorrections',
          path: 'production-orders-corrections-logs',
          component: () => import('@/views/production/reports/production-orders-corrections-logs'),
          meta: {
            title: 'Production Orders Corrections Logs',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.prodOrdersCorrectionsRoles
          }
        },
        {
          name: 'MasterProductionReport',
          path: 'master-production-report',
          component: () => import('@/views/production/reports/master-production-report'),
          meta: {
            title: 'Master Production Report',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'BatchingSummary',
          path: 'reports/batching-summary',
          component: () => import('@/views/production/reports/batching-summary'),
          meta: {
            title: 'Batching Summary',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'BatchingLogs',
          path: 'reports/batching-logs',
          component: () => import('@/views/production/reports/batching-logs'),
          meta: {
            title: 'Batching Logs',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'BatchingKPI',
          path: 'reports/batching-kpi',
          component: () => import('@/views/production/reports/batching-kpi'),
          meta: {
            title: 'Batching KPI',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'ProductionSummary',
          path: 'reports/production-summary',
          component: () => import('@/views/production/reports/production-summary'),
          meta: {
            title: 'Production Summary',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'ProductionLogs',
          path: 'reports/production-logs',
          component: () => import('@/views/production/reports/production-logs'),
          meta: {
            title: 'Production Logs',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'ProductionVolumeVariance',
          path: 'reports/production-volume-variance',
          component: () => import('@/views/production/reports/production-volume-variance'),
          meta: {
            title: 'Production Volume Variance',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'StampingLogs',
          path: 'reports/stamping-logs',
          component: () => import('@/views/production/reports/stamping-logs'),
          meta: {
            title: 'Stamping Logs',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'StampingWastedBottles',
          path: 'reports/stamping-wasted-bottles',
          component: () => import('@/views/production/reports/stamping-wasted-bottles'),
          meta: {
            title: 'Stamping Wasted Bottles',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'StampsInventoryReport',
          path: 'reports/stamps-inventory',
          component: () => import('@/views/production/reports/stamps-inventory-report'),
          meta: {
            title: 'Stamps Inventory Report',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'ReelAdjustmentLog',
          path: 'reports/reel-adjustment-log',
          component: () => import('@/views/production/reports/reel-adjustment-logs'),
          meta: {
            title: 'Reel Adjustment Log',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'TransferReport',
          path: 'reports/transfer-report',
          component: () => import('@/views/production/reports/transfer-report'),
          meta: {
            title: 'Transfer Report',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'TransferLogs',
          path: 'reports/transfer-logs',
          component: () => import('@/views/production/reports/transfer-logs'),
          meta: {
            title: 'Transfer Logs',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'PailsTransferLogs',
          path: 'reports/pails-transfer-logs',
          component: () => import('@/views/production/reports/pails-transfer-logs'),
          meta: {
            title: 'Pails Transfer Logs',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'DailyOrdersReport',
          path: 'reports/daily-orders-report',
          component: () => import('@/views/production/reports/daily-orders-report'),
          meta: {
            title: 'Daily Orders Report',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'DailyOrdersList',
          path: 'reports/daily-orders-list',
          component: () => import('@/views/production/reports/daily-orders-list'),
          meta: {
            title: 'Daily Orders List',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'HplcReport',
          path: 'reports/hplc-report',
          component: () => import('@/views/production/reports/hplc-report'),
          meta: {
            title: 'HPLC Report',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'Recipes',
          path: 'reports/recipes',
          component: () => import('@/views/production/reports/recipes'),
          meta: {
            title: 'Recipe Reports',
            icon: 'file-spreadsheet',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        },
        {
          name: 'ProductionReportsSettings',
          path: 'settings',
          component: () => import('@/views/production/reports/settings/index'),
          meta: {
            title: 'Reports Settings',
            icon: 'settings',
            noCache: true,
            roles: roles.Production_SectionRoles_Report
          }
        }
      ]
    }
  ]
}

export default productionRouter
