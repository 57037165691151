import { Message } from 'element-ui'

const queueSize = 2

const messageInstances = []
const resetMessage = (options) => {
  const messageInstance = messageInstances.length >= queueSize ? messageInstances.shift() : null
  if (messageInstance) {
    messageInstance.close()
  }

  messageInstances.push(Message(options))
}

;['error', 'success', 'info', 'warning'].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})

export const message = resetMessage
