import Cookies from 'js-cookie'

const api = Cookies.withAttributes({ sameSite: 'strict' })
let state
try {
  state = {
    guides_done: Cookies.get('guides_done') ? JSON.parse(Cookies.get('guides_done')) : []
  }
} catch (error) {
  state = { guides_done: [] }
}

const mutations = {
  SET_READ: (state, guide) => {
    if (!state.guides_done.includes(guide)) {
      state.guides_done.push(guide)
      api.set('guides_done', JSON.stringify(state.guides_done))
    }
  }
}

const actions = {
  setRead({ commit }, guide) {
    commit('SET_READ', guide)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
