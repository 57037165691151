import Vue from 'vue'
import { MessageBox } from 'element-ui'

export const helpers = {
  getFilledInfo: (item, key, defaultValue = null) => {
    let value = null

    if ((Array.isArray(item) || typeof item === 'object') && item !== null) {
      value = item[key] || null
    } else {
      value = item
    }

    if (value === null || value === '' || !value) {
      if (defaultValue === null) {
        value = !isNaN(value) || typeof value === 'boolean' ? value : null // keep 0 or false
      } else {
        value = defaultValue
      }
    }

    return value
  },
  updateURI: (params) => {
    let finalUrl = window.location.href + (window.location.href.includes('?') ? '' : '?')
    for (var x in params) {
      finalUrl += x + '=' + params[x]
    }
    window.history.pushState({}, null, new URL(finalUrl))
  },
  sortByField: (sortArray, propertyName, reverse = false) => {
    return sortArray.sort(function (a, b) {
      var item1 = a[propertyName]
      var item2 = b[propertyName]
      let result = 0

      if (item1 + 0 === item1 && item2 + 0 === item2) result = item1 - item2 // numbers
      else result = item1.localeCompare(item2) // strings

      if (reverse) result *= -1

      return result
    })
  }
}

/**
 * @param {string} file
 * @returns {string}
 */
export function getURL(file) {
  return process.env.VUE_APP_BASE_API + file
}

/**
 * @param {object} response
 */
export function messageError(response) {
  let message
  // if validation error, display the validation message
  if (response.data) {
    message = Object.keys(response.data)
      .map((key) => `${key}: ${response.data[key]}`)
      .join(',')
  } else {
    message = response.message
  }

  Vue.prototype.$notificationMessage({
    title: 'Error',
    message,
    type: 'error'
  })
}

/**
 * @param {object} response
 */
export function handleValidationWithArrays(response) {
  let message
  // if validation error, display the validation message
  if (response.data) {
    message = Object.keys(response.data)
      .map((key) => `${response.data[key]}`)
      .join(',')
  } else {
    message = response.message
  }

  Vue.prototype.$notificationMessage({
    title: 'Error',
    message,
    type: 'error'
  })
}

/**
 * @param {string} value
 * @returns {string}
 */
export function formatLabel(value) {
  value = value.trim().replace(/_/g, ' ').toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function formatJson(gridColumns, gridData) {
  const data = []
  const header = []

  // create headers
  gridColumns.forEach((column) => {
    header.push(column.label)
  })

  // format data
  gridData.forEach((item, i) => {
    data[i] = []
    gridColumns.forEach((column) => {
      if (column.export) {
        data[i].push(parseValues(item[column.key], 'type' in column ? column.type : null))
      }
    })
  })

  return { data, header }
}

export function deepCopy(inObject) {
  let value, key

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value)
  }

  return outObject
}

/**
 * @param {Object} vueContext
 * @param {string} type
 * @param {string} text
 */
export function notify(response, entity = '') {
  const warn = response.status ? ' successfully executed' : ' not executed'
  const message = response.message ? response.message : entity + warn
  Vue.prototype.$notificationMessage({
    title: response.status ? 'Success' : 'Error',
    message,
    type: response.status ? 'success' : 'error'
  })
}

/**
 * @param {string|number} value
 * @param {string} key
 * @returns {string}
 */
export function parseValues(value, type, back = false, tranform) {
  switch (type) {
    case 'yesOrNo':
      if (!back) {
        return value ? 'Yes' : 'No'
      } else {
        return value
      }
    case 'status':
      if (!back) {
        return value ? 'Active' : 'Inactive'
      } else {
        return value
      }
    case 'number':
    case 'list':
    case 'customList':
    case 'currency':
    case 'percent':
      return value
    default:
      if (tranform) {
        return tranform(value)
      } else if (Array.isArray(value) && value.length) {
        return value
      } else if (value) {
        return String(value)
      } else {
        return value
      }
  }
}

export function formatDate(date, type = null) {
  switch (type) {
    case 'date':
      return date ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY') : ''
    case 'date_sql':
      return date ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD') : ''
    case 'time':
      return date ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('HH:mm:ss') : ''
    case 'serverDate':
      return date ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('YYYY-MM-DD HH:mm:ss') : ''
    case 'date_mdy':
      return date ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM-DD-YYYY') : ''
    default:
      return date ? Vue.prototype.$moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY HH:mm:ss') : ''
  }
}

/**
 * @param {Object} form
 * @returns {FormData}
 */
export function parse2FormData(form, formData = new FormData()) {
  for (var x in form) {
    // to make it simple, delete first and add again
    if (formData.has(x)) {
      formData.delete(x)
    }
    if (formData.has(x + '[]')) {
      formData.delete(x + '[]')
    }

    if (Array.isArray(form[x])) {
      form[x].forEach((e) => {
        if (e) {
          formData.append(x + '[]', e)
        }
      })
    } else {
      if (form[x]) {
        formData.append(x, form[x])
      }
    }
  }

  return formData
}

/**
 * @param {Object} temp
 * @param {Array} columns
 * @returns {string}
 */
export function parseToShow(temp, columns) {
  for (const x in temp) {
    for (const y in columns) {
      if (x === columns[y].key) {
        if (typeof columns[y].value !== 'undefined' && temp[x] === '') {
          temp[x] = parseValues(columns[y].value, columns[y].type)
        } else {
          temp[x] = parseValues(temp[x], columns[y].type)
        }
      }
    }
  }
  return temp
}

/**
 * @param {Object} temp
 * @param {Array} columns
 * @returns {string}
 */
export function parseToSend(temp, columns) {
  const result = {}

  for (const x in temp) {
    result[x] = temp[x]
    for (const y in columns) {
      if (x === columns[y].key && columns[y].form) {
        result[x] = parseValues(temp[x], columns[y].type, true)
      }
    }
  }
  return result
}
/**
 * @param {Object} temp
 * @param {Array} columns
 * @returns {string}
 */
export function parseFilterToSend(temp, columns) {
  const result = {}

  for (const x in temp) {
    for (const y in columns) {
      if (columns[y].key === x) {
        result[x] = parseValues(temp[x], columns[y].type, true)
      } else {
        result[x] = temp[x]
      }
    }
  }
  return result
}

/**
 * @param {Array} fields
 * @returns {Array}
 */
export function createRule(fields) {
  const rules = []

  for (const x in fields) {
    switch (fields[x].type) {
      case 'required':
        rules.push({
          required: true,
          message: fields[x].label + ' is required',
          trigger: 'blur'
        })
        break
      case 'valid_email':
        rules.push({
          type: 'email',
          message: 'The field must be a valid email'
        })
        break
      case 'greater':
        rules.push({
          type: 'number',
          min: 1,
          message: fields[x].label + ' must be greater than 0'
        })
        break
    }
  }

  return rules
}

/**
 * @param {string} message
 * @param {object} data
 * @returns {string}
 */
export function parseApiError(type, data) {
  let result

  switch (type) {
    case 'validation_error':
      result = []
      for (const x in data) {
        let new_message = ''
        switch (data[x]) {
          case 'exists':
            new_message = 'This option must exist in database'
            break
          case 'digits':
          case 'digitsbetween':
            new_message = 'This field must have the right size'
            break
          case 'numeric':
            new_message = 'This field must have only numbers'
            break
          case 'integer':
            new_message = 'This field must have only integers'
            break
          case 'required':
            new_message = 'This field is required'
            break
          case 'email':
            new_message = 'This field must be a valid email'
            break
          case 'requiredif':
            new_message = 'This field is required' // @todo improve this message
            break
          case 'unique':
            new_message = 'This field must be unique'
            break
          case 'max':
            new_message = 'Too long information for this field'
            break
          case 'in':
            new_message = 'Invalid option for this field'
            break
          case 'size':
            new_message = 'Invalid size for this field'
            break
          case 'more100%':
            new_message = 'The sum is greater than 100%'
            break
          default:
            new_message = data[x]
            break
        }
        result.push({ field: x, msg: new_message })
      }
      result.message = 'Errors found in the submitted data, please check.'
      break

    case 'database':
      switch (data) {
        case 'database_delete_1451':
          result = 'There are other records related to this item'
          break
        case 'database_delete_7':
          result = 'There are other records related to this item'
          break
        case 'no_privileges':
          result = 'You are not allowed to delete this record'
          break
        default:
          result = data
      }
      break
  }

  return result
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args2) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args2)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/**
 * Send notification message
 */
export function notificationMessage({
  type = 'info',
  title = 'Alert',
  message = '',
  duration = -1,
  dangerouslyUseHTMLString = false
} = {}) {
  if (type !== 'error') {
    if (duration < 0) {
      switch (type) {
        case 'success':
          duration = 5000
          break
        case 'warning':
          duration = 10000
          break
        case 'info':
          duration = 10000
          break
        default:
          duration = 10000
          break
      }
    }

    Vue.prototype.$notify({
      title,
      message,
      type,
      duration,
      showClose: true,
      dangerouslyUseHTMLString
    })
  } else {
    MessageBox.alert(message, title, {
      confirmButtonText: 'OK',
      type: 'error',
      showClose: true,
      dangerouslyUseHTMLString
    })
  }
}

/**
 * Send validation error message
 */
export function responseErrorMessage(response) {
  if (response.message === 'validation_error') {
    this.$notificationMessage({
      title: 'Validation Error',
      message: Object.values(response.data).join('<br>'),
      type: 'error',
      dangerouslyUseHTMLString: true
    })
  } else {
    this.$notificationMessage({
      title: 'Error',
      message: response.message,
      type: 'error'
    })
  }
}

export function closeSelectedTab(route_name, store) {
  if (route_name !== '') {
    const currentView = store.state.tagsView.visitedViews.find(function (view) {
      return view.name === route_name
    })
    store.dispatch('tagsView/delView', currentView)
  }
}

export function parseFileName(file_name) {
  return file_name.replaceAll(' ', '_').replaceAll('/', '').replaceAll('\\', '')
}
