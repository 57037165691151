/** When your routing table is too long, you can split it into small modules **/

// Layout with Header
import Layout from '@/layout'
// Roles for Permissions
import roles from './roles'

const saleRouter = {
  path: '/sales',
  component: Layout,
  redirect: 'noRedirect',
  name: 'SaleModule',
  alwaysShow: true,
  meta: {
    module: 'Sales',
    title: 'Sale',
    icon: 'grocery',
    roles: roles.Sale_SectionRoles_Section
  },
  children: [
    {
      name: 'Sales',
      path: 'sales',
      component: () => import('@/views/sales/sales'),
      meta: { title: 'Sales', icon: 'recipe-ingredients' }
    },
    {
      name: 'SaleForm',
      path: '/sale/:id?',
      component: () => import('@/views/sales/sale-form'),
      meta: { title: 'Sale - Edit', icon: 'Sale' },
      hidden: true
    },
    {
      name: 'SalesImportLog',
      path: '/sales-import-logs',
      component: () => import('@/views/sales/sales-import-logs'),
      meta: { title: 'Sales - Error logs from import', icon: 'Sale' },
      hidden: true
    },
    {
      name: 'salesDiscounts',
      path: 'discounts',
      component: () => import('@/views/sales/discounts'),
      meta: { title: 'Discounts', icon: 'tags' }
    },
    {
      name: 'DiscountForm',
      path: '/discount/:id?',
      component: () => import('@/views/sales/discount-form'),
      meta: { title: 'Discount - Edit', icon: 'tag' },
      hidden: true
    }
  ]
}

export default saleRouter
