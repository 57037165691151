import Cookies from 'js-cookie'

const api = Cookies.withAttributes({ sameSite: 'strict' })

const TokenKey = 'NextERP-Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return api.set(TokenKey, token)
}

export function removeToken() {
  return api.remove(TokenKey)
}
